<script setup lang="ts" generic="T extends { id: string; link: string }">
import type { AsyncDataRequestStatus } from "#app"

defineProps<{
  searchItems: T[]
  status: AsyncDataRequestStatus
  minSearchTermLength?: number
}>()

const open = defineModel<boolean>("open")
const searchTerm = defineModel<string>("searchTerm")

const focusedItem = ref<HTMLAnchorElement>()
const linkContainer = ref<HTMLDivElement>()

const input = ref<HTMLInputElement>()
watchEffect(() => {
  if (open.value && input.value) {
    input.value.focus()
  }
})

/** Automatically focuses the first search result when the user starts typing */
const autoFocusFirstItem = ref(false)

watch([searchTerm, focusedItem], () => {
  const inputHasFocus = input.value == document.activeElement
  if (searchTerm.value && inputHasFocus) {
    autoFocusFirstItem.value = true
  } else {
    autoFocusFirstItem.value = false
  }
})

defineEmits<{ (e: "item-selected", item: T): void }>()

watch(open, () => {
  if (!open.value) {
    searchTerm.value = ""
  }
})
function onNextElement() {
  if (!linkContainer.value || !linkContainer.value.children[0]) {
    return
  }
  if (!focusedItem.value) {
    focusedItem.value = autoFocusFirstItem.value
      ? (linkContainer.value.children[1] as HTMLAnchorElement)
      : (linkContainer.value.children[0] as HTMLAnchorElement)
    focusedItem.value?.focus()
    return
  }
  const next = focusedItem.value?.nextElementSibling as
    | HTMLAnchorElement
    | undefined
  if (next) {
    next.focus()
    focusedItem.value = next
  }
}
function onPreviousElement() {
  const previous = focusedItem.value?.previousElementSibling as
    | HTMLAnchorElement
    | undefined
  if (previous) {
    previous.focus()
    focusedItem.value = previous
  } else {
    // Focus input field when reaching top of list
    input.value?.focus()
  }
}
</script>
<template>
  <DialogWrapper v-model:open="open">
    <Transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="transform opacity-0 translate-y-40"
      enter-to-class="opacity-100 translate-y-0"
      leave-active-class="duration-200 ease-in"
      leave-from-class="opacity-100 translate-y-0"
      leave-to-class="transform opacity-0 translate-y-40"
      appear
    >
      <dialog
        v-if="open"
        :open
        class="fixed inset-x-0 top-20 z-50 mx-auto w-full max-w-2xl overflow-hidden rounded-xl bg-slate-900 text-white lg:w-1/2"
        @keydown.up.prevent="onPreviousElement"
        @keydown.down.prevent="onNextElement"
        @keydown.tab="
          () => {
            if (autoFocusFirstItem) {
              focusedItem = linkContainer?.children[0] as HTMLAnchorElement
              focusedItem.focus()
            }
          }
        "
      >
        <div class="relative border-b border-slate-800">
          <div
            class="pointer-events-none absolute left-0 flex h-12 items-center pl-3"
          >
            <SvgoPikaSearch class="absolute size-5 flex-none text-slate-400" />
          </div>
          <input
            ref="input"
            v-model="searchTerm"
            data-testid="input_search"
            type="text"
            :placeholder="$t('search.placeholder')"
            class="h-12 w-full border-0 bg-transparent pr-4 pl-10 text-white placeholder-slate-500 focus:ring-0 sm:text-sm"
            @keydown.enter="
              () => {
                if (autoFocusFirstItem) {
                  navigateTo(searchItems[0]?.link)
                  open = false
                }
              }
            "
          />
        </div>
        <div
          v-if="!searchTerm && $slots.emptyHeader"
          class="border-b border-slate-800 px-4 pt-2 pb-4"
        >
          <slot name="emptyHeader" />
        </div>
        <div
          class="overflow-y-scroll"
          :class="{
            'h-64': !searchItems?.length,
            'max-h-96': searchItems?.length,
          }"
          :style="{ scrollbarColor: 'rgb(60 96 120) rgb(15 23 42)' }"
        >
          <div
            v-if="searchTerm && status != 'success'"
            class="flex items-center justify-center pt-4"
          >
            <AnimationsLoadingSpinner dark />
          </div>
          <div
            v-else-if="searchItems?.length"
            ref="linkContainer"
            class="flex flex-col"
          >
            <!--
              Custom NuxtLink is used here because the NuxtLink component does not support adding event handlers directly.
              This results in TypeScript errors when using vueCompilerOptions strictTemplates.
              This behavior may be due to a bug in vue-router. See issue: https://github.com/vuejs/router/issues/2484
            -->
            <NuxtLink
              v-for="(item, index) in searchItems"
              v-slot="{ href, navigate }"
              :key="item.id"
              custom
              class="px-4 py-2 outline-hidden hover:bg-slate-800 focus:bg-slate-800"
              :class="autoFocusFirstItem && index == 0 ? 'bg-slate-800' : ''"
              :tabindex="0"
              :data-testid="`searchresult_${index}`"
              :to="item.link"
            >
              <a
                :href
                @focus="
                  (e: FocusEvent) =>
                    (focusedItem = e.target as HTMLAnchorElement)
                "
                @blur="focusedItem = undefined"
                @mouseover="autoFocusFirstItem = false"
                @click.prevent="
                  () => {
                    navigate()
                    $emit('item-selected', item)
                    open = false
                  }
                "
                @keydown.enter="
                  () => {
                    $emit('item-selected', item)
                    navigate()
                    open = false
                  }
                "
              >
                <slot :item />
              </a>
            </NuxtLink>
          </div>
          <div
            v-else-if="
              (searchTerm?.length && !minSearchTermLength) ||
              (minSearchTermLength &&
                searchTerm?.length &&
                searchTerm.length >= minSearchTermLength)
            "
            class="flex h-full w-full flex-col items-center justify-center gap-2"
          >
            <slot name="noResults" />
          </div>
          <div
            v-else
            class="flex h-full w-full flex-col items-center justify-center gap-2"
          >
            <slot name="empty" />
          </div>
        </div></dialog
    ></Transition>
  </DialogWrapper>
</template>
