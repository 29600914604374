<script setup lang="ts">
const {
  status,
  data: videos,
  execute,
} = await useCachedFetch("/videos", {
  method: "post",
  immediate: false,
})

const open = defineModel<boolean>("open")
const searchTerm = ref("")

watch(open, async () => {
  // Fetch when opened first time. Component is always mounted and we don't want to fetch on page load
  if (status.value != "success") {
    execute()
  }
})

const videosWithLink = computed(() => {
  if (!videos.value) return []
  return Object.values(videos.value)
    .flat()
    .map((video) => ({
      ...video,
      link: `/videos/${camelToKebab(video.category ?? "")}/video/${video.id}`,
    }))
})

const filteredVideos = computed(() => {
  if (searchTerm.value.length == 0) {
    return []
  }
  return Object.values(videosWithLink.value).filter((video) =>
    video.title
      .toLocaleLowerCase()
      .includes(searchTerm.value.toLocaleLowerCase()),
  )
})

const { trackEvent } = useAnalytics()
function trackSearchResult(video: Video) {
  trackEvent("search_result_clicked", {
    lesson_id: video.id,
    lesson_type: "video",
  })
}
</script>

<template>
  <SearchDialog
    v-model:open="open"
    v-model:search-term="searchTerm"
    :search-items="filteredVideos"
    :status
    @dialog-opened-first-time="execute()"
    @item-selected="trackSearchResult"
  >
    <template #emptyHeader>
      <span class="text-sm font-bold">{{ $t("search.popular") }}</span>
      <ul class="mt-2 flex flex-col gap-1 text-sm">
        <li v-for="term in $tm('search.popular_terms')" :key="term">
          <button
            class="text-gray-400 transition hover:text-gray-200"
            @click="searchTerm = term"
          >
            {{ term }}
          </button>
        </li>
      </ul>
    </template>
    <template #empty>
      <SvgoPikaFile class="size-8 text-slate-500" />
      <span class="text-slate-300">
        {{ $t("search.empty") }}
      </span>
    </template>
    <template #noResults>
      <SvgoPikaWarning class="size-8 text-slate-500" />
      <span class="text-slate-300">
        {{ $t("search.no_results") }}
      </span>
    </template>
    <template #default="{ item }">
      <div class="flex items-center gap-6">
        <img
          :src="item.image"
          class="aspect-video h-min w-1/6 rounded-sm"
          :alt="item.title"
        />
        <div class="flex flex-col truncate overflow-hidden">
          <TypographyHeading class="text-slate-200!" :level="4">{{
            item.title
          }}</TypographyHeading>
          <span class="gap-4 truncate text-sm font-light text-slate-200">{{
            item.description
          }}</span>
        </div>
      </div>
    </template>
  </SearchDialog>
</template>
